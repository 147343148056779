import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Albums } from "./pages/Albums";
import { Home } from "./pages/Home";
import { Invitations } from "./pages/Invitations";
import { ShowInvitation } from "./pages/ShowInvitation";

export const AllRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" index element={<Home />} />
      <Route path="/:category" element={<Albums />} />
      <Route path="/:category/album/:albumId" exact element={<Invitations />} />
      <Route
        path="/:category/album/:albumId/i/:invitationCode"
        exact
        element={<ShowInvitation />}
      />
    </Routes>
  </BrowserRouter>
);
