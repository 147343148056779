import "./styles.css";
import logo from "../../assets/logo/logo_cl.png";

export const Header = ({ onBack }) => {
  return (
    <div className="art-header bg-header">
      <div className="container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="color-grey"
        >
          <div>
            {onBack ? (
              <div className="arrow" onClick={onBack}></div>
            ) : (
              <span>
                <img src={logo} width="110px" alt="logo" />
              </span>
            )}
          </div>
          <div>
            <a
              href="/"
              className="color-grey color-grey-hover"
              style={{ textDecoration: "none", color: "#2b2b2b" }}
            >
              {window.location.host.toLowerCase()}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
