import React from "react";
import { Link } from "react-router-dom";
import { Box } from "../box/Box";

export const Breadcrumb = ({ routes }) => {
  const capitalizeValue = (value) => {
    const firstChar = value[0].toUpperCase();
    return `${firstChar}${value.substring(1, value.length).toLowerCase()}`;
  };

  return (
    <Box small>
      <div className="color-grey">
        {routes.map((item, index) => (
          <React.Fragment key={index}>
            <span className="ms-2">
              {item.href ? (
                <Link className="color-grey color-grey-hover" to={item.href}>
                  {capitalizeValue(item.name)}
                </Link>
              ) : (
                capitalizeValue(item.name)
              )}
            </span>
            {index !== routes.length - 1 ? (
              <span className="ms-2">
                <b>/</b>
              </span>
            ) : null}
          </React.Fragment>
        ))}
      </div>
    </Box>
  );
};
