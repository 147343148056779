export const getLocaleFromHost = () => {
  const host = window.location.host;
  switch (host) {
    case "pokani.mk":
      return "mk";
    case "ftesa.mk":
    case "ftesa.com.mk":
      return "al";
    default:
      return "al";
  }
};
