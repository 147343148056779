import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "jquery/dist/jquery";
import "popper.js/dist/popper";
import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import translations_en from "./assets/translations/translations_en.json";
import translations_al from "./assets/translations/translations_al.json";
import translations_mk from "./assets/translations/translations_mk.json";
import { getLocaleFromHost } from "./utils/init";

i18next.init({
  interpolation: { escapeValue: false },
  lng: getLocaleFromHost(),
  resources: {
    en: { art: translations_en },
    al: { art: translations_al },
    mk: { art: translations_mk },
  },
});

document.title = window.location.host;

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
