import { Box } from "../components/box/Box";
import { Header } from "../components/header/Header";
import { Breadcrumb } from "../components/breadcrumb/Breadcrumb";
import { Items } from "../components/items/Items";
import { useNavigate, useParams } from "react-router";
import { getAlbumsByCategory, mapDataToItemData } from "../utils/functions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const Albums = () => {
  const navigate = useNavigate();
  const { category } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [albums, setAlbums] = useState([]);
  const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]);
  const [t] = useTranslation("art");

  useEffect(() => {
    const initData = async () => {
      if (category) {
        const response = await getAlbumsByCategory(category);
        if (response?.status !== 200) {
          setError(t("error"));
        } else {
          setAlbums(response.data.data);
        }
        setLoading(false);
      }
    };

    setBreadcrumbRoutes([
      {
        name: t(category),
      },
    ]);

    initData();
  }, [category, t]);

  return (
    <>
      <Header onBack={() => navigate("/")} />
      <div className="container my-4">
        <Breadcrumb routes={breadcrumbRoutes} />
      </div>
      {albums && (
        <div className="container my-4">
          {error ? (
            <div className="container">
              <div className="alert alert-danger">{error}</div>
            </div>
          ) : (
            <Box>
              {loading ? (
                <div className="container text-center pb-3">
                  <div className="spinner-border" role="status"></div>
                </div>
              ) : (
                <Items data={mapDataToItemData(albums, `${category}/album`)} />
              )}
            </Box>
          )}
        </div>
      )}
    </>
  );
};
