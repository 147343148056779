import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { resolveImageUrl } from "../../utils/functions";
import "./styles.css";

export const Item = ({ image, title, href, full, lg }) => {
  const ref = useRef(null);
  const [height, setHeight] = useState("auto");
  const navigate = useNavigate();

  const screenWidth = window.screen.width;

  useEffect(() => {
    if (!full) {
      if (screenWidth <= 1024) {
        if (ref.current) {
          setHeight(ref.current.offsetWidth);
        }
      } else {
        if (ref.current) {
          let h = lg ? 1.5 : 2;
          setHeight(ref.current.offsetWidth / h);
        }
      }
    }
  }, [ref, screenWidth, lg, full]);

  const navigateToItem = () => {
    navigate(href, {
      state: {
        from: window.location.pathname,
      },
    });
  };

  return (
    <div
      className={`${
        full ? "col-12 col-sm-12 col-md-12" : "col-6 col-sm-6 col-md-6"
      } col-lg-4 col-xl-4 mb-4 text-center`}
    >
      <div
        ref={ref}
        style={{ height: height }}
        className={`item ${full ? "item-auto" : ""}`}
        onClick={navigateToItem}
      >
        <img src={full ? image : resolveImageUrl(image)} alt="Invitation" />
      </div>
      <p className="mb-0 mb-md-1 mt-1 mt-mb-2 color-grey">{title}</p>
    </div>
  );
};
