import { Box } from "../components/box/Box";
import { Header } from "../components/header/Header";
import weddingCover from "../assets/cover_wedding.jpg";
import sunnetCover from "../assets/cover_sunnet.jpg";
import otherCover from "../assets/cover_other.jpg";
import { Item } from "../components/items/Item";
import { useTranslation } from "react-i18next";

export const Home = () => {
  const [t] = useTranslation("art");
  return (
    <>
      <Header />
      <div className="container d-center my-4">
        <Box>
          <div className="row">
            <Item
              full
              lg
              image={weddingCover}
              title={t("wedding")}
              href="/wedding"
            />
            <Item
              full
              lg
              image={sunnetCover}
              title={t("sunnet")}
              href="/sunnet"
            />
            <Item full lg image={otherCover} title={t("other")} href="/other" />
          </div>
        </Box>
      </div>
    </>
  );
};
