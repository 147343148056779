import { useTranslation } from "react-i18next";
import { Item } from "./Item";

export const Items = ({ data, customHref }) => {
  const [t] = useTranslation("art");
  return (
    <div className="row">
      {data.length > 0 ? (
        data.map(({ id, title, href, image }) => (
          <Item
            key={id}
            id={id}
            title={title}
            href={customHref ? customHref.replace(":code", title) : href}
            image={image}
          />
        ))
      ) : (
        <div className="mb-4 text-center text-black-50">
          {t("noDataAvailable")}!
        </div>
      )}
    </div>
  );
};
