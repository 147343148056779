import React, { useEffect, useState } from "react";
import "./styles.css";
import { useSwipeable } from "react-swipeable";

export const Carousel = ({ children, index, onIndexChange }) => {
  const [activeIndex, setActiveIndex] = useState(index || 0);
  const [indicatorIndex, setIndicatorIndex] = useState(0);

  const handleKeyDown = (e) => {
    if (e.keyCode === 37) {
      decreaseIndexes();
    } else if (e.keyCode === 39) {
      increaseIndexes();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  const updateIndex = (newIndex) => {
    const childrenLength = React.Children.count(children);

    if (newIndex < 0) {
      newIndex = childrenLength - 1;
    } else if (newIndex >= childrenLength) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  const updateIndicatorIndex = (newIndex) => {
    if (newIndex === 8) {
      setIndicatorIndex(0);
    } else if (newIndex === -1) {
      setIndicatorIndex(7);
    } else {
      setIndicatorIndex(newIndex);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => increaseIndexes(),
    onSwipedRight: () => decreaseIndexes(),
  });

  const increaseIndexes = () => {
    updateIndex(activeIndex + 1);
    onIndexChange(activeIndex + 1);
    updateIndicatorIndex(indicatorIndex + 1);
  };

  const decreaseIndexes = () => {
    updateIndex(activeIndex - 1);
    onIndexChange(activeIndex - 1);
    updateIndicatorIndex(indicatorIndex - 1);
  };

  return (
    <div
      {...handlers}
      className="invitation-carousel"
      onKeyDown={(e) => handleKeyDown(e)}
    >
      <div
        className="invitation-inner"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>
      <div className="invitation-indicators">
        {Array.from(new Array(8)).map((_, index) => (
          <div
            key={index}
            className={`invitation-indicators-item  ${
              index === indicatorIndex ? "indicator-active" : ""
            } `}
          ></div>
        ))}
      </div>
    </div>
  );
};
