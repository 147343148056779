import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Box } from "../components/box/Box";
import { Breadcrumb } from "../components/breadcrumb/Breadcrumb";
import { Header } from "../components/header/Header";
import { Items } from "../components/items/Items";
import {
  getAlbumsByCategory,
  getInvitationsByAlbumId,
  mapDataToItemData,
} from "../utils/functions";

export const Invitations = () => {
  const navigate = useNavigate();
  const { category, albumId } = useParams();
  const location = useLocation();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [invitations, setInvitations] = useState([]);
  const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]);
  const [t] = useTranslation("art");

  useEffect(() => {
    const initData = async () => {
      if (albumId) {
        const response = await getInvitationsByAlbumId(albumId);
        if (response?.status !== 200) {
          setError(t("error"));
        } else {
          setInvitations(response.data.data);
        }
        setLoading(false);
      }
    };

    const initBreadcrumbs = async () => {
      let album;
      const albumResponse = await getAlbumsByCategory(category);
      const albums = albumResponse?.data?.data;
      if (albums) {
        album = albums.find((a) => a.id === parseInt(albumId))?.name;
      }
      setBreadcrumbRoutes([
        {
          name: t(category),
          href: `/${category}`,
        },
        {
          name: album ?? t("invitations"),
        },
      ]);
    };

    initData();
    initBreadcrumbs();
  }, [albumId, location, category, t]);

  const goBack = location?.state?.from ?? "/";

  return (
    <>
      <Header onBack={() => navigate(goBack)} />
      <div className="container my-4">
        <Breadcrumb routes={breadcrumbRoutes} />
      </div>
      {error ? (
        <div className="container">
          <div className="alert alert-danger">{error}</div>
        </div>
      ) : (
        <div className="container my-4">
          {invitations && (
            <Box>
              {loading ? (
                <div className="container text-center pb-3">
                  <div className="spinner-border" role="status"></div>
                </div>
              ) : (
                <Items
                  data={mapDataToItemData(invitations, "invitation")}
                  customHref={`/${category}/album/${albumId}/i/:code`}
                />
              )}
            </Box>
          )}
        </div>
      )}
    </>
  );
};
