import axios from "axios";
import { getLocaleFromHost } from "./init";

export const mapDataToItemData = (data, href) => {
  return data.map((item) => ({
    id: item.id,
    title: item.code ?? item.name,
    image: item.cover_path,
    href: `/${href}/${item.code ?? item.id}`,
  }));
};

export const getLocale = () => {
  return getLocaleFromHost();
};

export const resolveImageUrl = (image) => {
  return `${process.env.REACT_APP_STORAGE_URL}/${image}`;
};

const baseUrl = process.env.REACT_APP_API_URL;

export const getAlbumsByCategory = (category) => {
  const url = `${baseUrl}/${getLocale()}/albums/category/${category}`;
  return axios
    .get(url)
    .then((response) => response)
    .catch((error) => error.response);
};

export const getInvitationsByAlbumId = (albumId) => {
  const url = `${baseUrl}/${getLocale()}/invitations/album/${albumId}`;
  return axios
    .get(url)
    .then((response) => response)
    .catch((error) => error.response);
};

export const getInvitationByCode = (code) => {
  const url = `${baseUrl}/${getLocale()}/invitations/${code}`;
  return axios
    .get(url)
    .then((response) => response)
    .catch((error) => error.response);
};
