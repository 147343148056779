import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { Breadcrumb } from "../components/breadcrumb/Breadcrumb";
import { Carousel } from "../components/carousel/Carousel";
import { CarouselItem } from "../components/carousel/CarouselItem";
import { Header } from "../components/header/Header";
import {
  getAlbumsByCategory,
  getInvitationsByAlbumId,
  resolveImageUrl,
} from "../utils/functions";
import "./styles.css";

export const ShowInvitation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { category, invitationCode, albumId } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [album, setAlbum] = useState();
  const [invitation, setInvitation] = useState(invitationCode);
  const [invitations, setInvitations] = useState([]);
  const [breadcrumbRoutes, setBreadcrumbRoutes] = useState([]);
  const [t] = useTranslation("art");

  useEffect(() => {
    const initData = async () => {
      if (invitationCode && albumId) {
        const albumResponse = await getInvitationsByAlbumId(albumId);
        if (albumResponse?.status !== 200) {
          setError(t("error"));
        } else {
          setInvitations(albumResponse.data.data);
        }
        setLoading(false);
      }
    };

    const initBreadcrumbs = async () => {
      const albumResponse = await getAlbumsByCategory(category);
      const albums = albumResponse?.data?.data;
      if (albums) {
        setAlbum(albums.find((a) => a.id === parseInt(albumId)));
      }
    };

    initData();
    initBreadcrumbs();
  }, [invitationCode, albumId, category, t]);

  useEffect(() => {
    setBreadcrumbRoutes([
      {
        name: t(category),
        href: `/${category}`,
      },
      {
        name: album?.name ?? t("invitations"),
        href: `/${category}/album/${album?.id}`,
      },
      {
        name: invitation,
      },
    ]);
  }, [category, album, invitation, t]);

  const goBack = location?.state?.from ?? "/";

  const resolveInvitationIndex = () => {
    const currentInvitation = invitations.find(
      (i) => i.code === invitationCode
    );
    return invitations.indexOf(currentInvitation);
  };

  const resolveIndexChange = (index) => {
    const invitation = invitations[index];
    if (invitation) {
      setInvitation(invitation.code);
      window.history.pushState(
        "",
        "",
        `/${category}/album/${albumId}/i/${invitation.code}`
      );
    }
  };

  return (
    <>
      <Header onBack={() => navigate(goBack)} />
      <div className="container my-4">
        <Breadcrumb routes={breadcrumbRoutes} />
      </div>
      {error ? (
        <div className="container">
          <div className="alert alert-danger">{error}</div>
        </div>
      ) : (
        <div className="container my-4">
          {loading ? (
            <div className="container text-center pb-3">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : (
            <>
              <div className="invitation">
                <Carousel
                  index={resolveInvitationIndex}
                  onIndexChange={resolveIndexChange}
                >
                  {invitations.map((invitation, i) => (
                    <CarouselItem key={invitation.code}>
                      <div className="invitation-frame mb-3">
                        <a
                          href={resolveImageUrl(invitation.cover_path)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={resolveImageUrl(invitation.cover_path)}
                            alt="Invitation"
                            className="w-100"
                          />
                        </a>
                      </div>
                    </CarouselItem>
                  ))}
                </Carousel>
                <h4 className="mt-3 mb-2 color-grey">
                  {t("code")}: {invitation}
                </h4>
                <div className="row m-auto">
                  <div className="col-12 col-md-4 p-0">
                    <button
                      onClick={() => {
                        window.location.href = "tel:+38971333995";
                      }}
                      className="bg-green my-2 my-md-0 mx-0 mx-md-2 col-12 col-md-10"
                    >
                      <i className="fas fa-phone-alt"></i> &nbsp;{t("phone")}
                    </button>
                  </div>
                  <div className="col-12 col-md-4 p-0">
                    <button
                      onClick={() => {
                        window.location.href =
                          "viber://chat?number=%2B38971333995";
                      }}
                      className="bg-purple my-2 my-md-0 mx-0 mx-md-2 col-12 col-md-10"
                    >
                      <i className="fab fa-viber"></i> &nbsp;Viber
                    </button>
                  </div>
                  <div className="col-12 col-md-4 p-0">
                    <button
                      onClick={() => {
                        window.location.href = `mailto:contact@art-photo.mk?subject=${invitation}`;
                      }}
                      className="bg-blue my-2 my-md-0 mx-0 mx-md-2 col-12 col-md-10"
                    >
                      <i className="far fa-envelope"></i> &nbsp;{t("email")}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
